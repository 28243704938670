html {
  overflow-x: hidden;
}
body {
  background-color: #f3f9f3 !important;
}
.css-u0k6ft-MuiPaper-root-MuiDrawer-paper,
.MuiDrawer-paperAnchorDockedLeft {
  margin: 0rem !important;
  border-radius: 0rem !important;
}
header.MuiAppBar-positionSticky {
  top: 0rem;
  background-color: #d5dbef !important;
  margin-bottom: 1rem;
  min-height: 3rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
header .MuiToolbar-regular .MuiIconButton-root .material-icons-round {
  font-size: 1.2rem !important;
}
.MuiDrawer-paperAnchorDockedLeft {
  background-color: #2f3f72 !important;
  /* background-color: #5c1e93 !important; */
  height: 100vh !important;
}
.MuiDrawer-paperAnchorDockedLeft .MuiBox-root h6 {
  color: #fff;
}
.MuiDrawer-paperAnchorDockedLeft .MuiListItemText-root {
  color: #eee;
}
.MuiDrawer-paperAnchorDockedLeft .active .MuiBox-root {
  background-color: #fff !important;
}
.MuiDrawer-paperAnchorDockedLeft .active .MuiListItemText-root {
  color: #333;
}
.MuiDrawer-paperAnchorDockedLeft .active .MuiBox-root .MuiListItemIcon-root {
  background: #5c1e93;
}
.MuiDrawer-paperAnchorDockedLeft .active .MuiBox-root .MuiListItemIcon-root svg,
.MuiDrawer-paperAnchorDockedLeft
  .active
  .MuiBox-root
  .MuiListItemIcon-root
  svg
  g {
  fill: #fff !important;
}
.MuiDrawer-paperAnchorDockedLeft span.MuiTypography-root.MuiTypography-caption {
  color: #eee;
}
.css-ocvocv {
  margin-top: 0px !important;
}
.css-br8e5i {
  margin-top: 0px !important;
}
.MuiTabs-flexContainer .MuiTab-root.Mui-selected {
  padding: 0.75rem 0.25rem;
}
button.MuiButton-colorPrimary {
  background: #5c1e93;
  background-color: #5c1e93;
  color: #fff;
  background-image: none !important;
}
button.MuiButton-colorPrimary:hover {
  background: #5c1e93;
  background-color: #5c1e93;
  color: #fff;
  background-image: none !important;
}
button.MuiButton-colorPrimary:focus:not(:hover),
button.MuiButton-colorPrimary::before {
  background: #00bdaa;
  background-color: #00bdaa;
  color: #fff;
  background-image: none !important;
}
.MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0.75rem 1.75rem 0.75rem 0.75rem !important;
}
.signin-admin-form .view-password .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0.75rem 0.75rem 0.75rem 0.75rem !important;
}
.view-password .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0.75rem 0.75rem 0.75rem 0.75rem !important;
}
.signin-admin-form
  .view-password
  .MuiInputBase-root.MuiOutlinedInput-root
  button
  svg {
  color: #5c1e93;
}
.view-password .MuiInputBase-root.MuiOutlinedInput-root button svg {
  color: #5c1e93;
}
.date-field .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0.75rem 0.75rem 0.75rem 0.75rem !important;
}
.active-btn {
  background-color: #fff !important;
  border: 2px solid #00bdaa;
  border-radius: 17px !important;
  color: #000000;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-grid;
  text-align: center;
  width: 80px;
}
.custom-calendar .rbc-event {
  font-size: 0.8rem;
  padding: 2px 4px;
}

.custom-calendar .rbc-event-label {
  font-size: 0.75rem;
}

.custom-calendar .rbc-day-slot .rbc-event-content {
  font-size: 0.8rem;
}

.upload-btn {
  background-color: #5c1e93 !important;
  color: #fff !important;
}
.success-btn {
  background: #00bdaa;
  background-color: #00bdaa !important;
  color: #fff !important;
}
.cancel-btn {
  background-color: #fe346e !important;
  color: #fff !important;
}
form label {
  margin-bottom: 5px !important;
}
form .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input {
  width: 100% !important;
}
form .MuiBox-root textarea {
  width: 100% !important;
  padding: 0.75rem 1.75rem 0.75rem 0.75rem !important;
  place-items: center !important;
  width: 100% !important;
  height: auto !important;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  color: #495057 !important;
  background-color: #ffffff !important;
  -webkit-transition: box-shadow 150ms ease, border-color 150ms ease,
    padding 150ms ease !important;
  transition: box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important;
  position: relative;
  border-radius: 4px;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 0.5rem 1.75rem 0.5rem 0.75rem !important;
  border: 0.0625rem solid #d2d6da;
  border-radius: 0.5rem !important;
}
form .MuiGrid-root label.MuiFormControlLabel-root {
  margin-left: 0px;
  margin-top: 25px;
}
.emp-ticket-assign form .MuiGrid-root label.MuiFormControlLabel-root {
  margin-top: 37.5px;
}
/* .edit-supervisor form .MuiGrid-root label.MuiFormControlLabel-root {
  margin-top: 0px;
} */
.profile-tabs .MuiTabs-root {
  background-color: transparent;
}
.profile-tabs form .MuiGrid-root label.MuiFormControlLabel-root {
  margin-top: 15px;
}
.profile-tabs .MuiFormGroup-root label {
  margin-left: 0px;
  margin-top: -10px;
}
.profile-tabs .MuiFormGroup-root label .MuiCheckbox-root {
  border: 1px solid #5c1e9386 !important;
}
.signin-admin-form .MuiCheckbox-root {
  border: 1.5px solid #5c1e9386 !important;
}
.profile-tabs .MuiTabs-flexContainer .MuiButtonBase-root {
  background-color: #f8f9fa;
  color: #5c1e93 !important;
  font-weight: bold;
  margin-right: 10px;
}
.profile-tabs .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected {
  background-color: #5c1e93;
  color: #fff !important;
}
.profile-tabs .MuiIconButton-root {
  color: #5c1e93 !important;
}
.profile-tabs .MuiRadio-root {
  border-color: #5c1e93;
}
.MuiCard-root .MuiButton-contained.MuiButton-colorPrimary {
  background: #00bdaa;
  color: #fff;
}
.MuiCard-root .manage-lifts .MuiButton-contained.MuiButton-colorPrimary {
  min-width: 125px;
}
.MuiCard-root .MuiPaper-root.MuiPaper-elevation .MuiToolbar-root {
  padding-left: 0px;
  padding-right: 0px;
}
table .MuiTableCell-head {
  color: #5c1e93;
  font-weight: 600;
}
.manage-tickets table .MuiTableCell-head:last-child {
  text-align: center;
}
.manage-tickets.m-category table .MuiTableCell-head:last-child {
  text-align: left;
}
table .MuiTableCell-head button.MuiButton-colorPrimary {
  background-color: transparent;
  color: #5c1e93;
  font-weight: 600;
}
table .MuiTableCell-head button.MuiButton-colorPrimary:focus:not(:hover) {
  background-color: transparent !important;
  color: #5c1e93 !important;
  font-weight: 600;
}
table .MuiTableCell-head button.MuiButton-colorPrimary div {
  color: #5c1e93 !important;
}
table td .MuiIconButton-root {
  color: #5c1e93;
  margin-right: 5px;
}
.manage-tickets table thead th:nth-child(6) {
  min-width: 125px;
}
.manage-tickets .MuiButtonBase-root.MuiChip-deletable {
  margin-bottom: 8px;
}
.manage-subscriptions .MuiButtonBase-root.MuiChip-deletable {
  margin-bottom: 8px;
}
.manage-customers .MuiButtonBase-root.MuiChip-deletable {
  margin-bottom: 8px;
}
.manage-employees .MuiButtonBase-root.MuiChip-deletable {
  margin-bottom: 8px;
}
.manage-supervisors .MuiButtonBase-root.MuiChip-deletable {
  margin-bottom: 8px;
}
.manage-lifts .tss-1vsygk-MUIDataTableFilterList-root {
  position: absolute;
  margin-left: 0px;
}
.manage-lifts .MuiButtonBase-root.MuiChip-deletable {
  margin-bottom: 8px;
}
.manage-lifts {
  position: relative;
}
.manage-lifts .tss-1cdcmys-MUIDataTable-responsiveBase {
  margin-top: 50px;
}
.manage-lifts table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
  position: absolute;
  top: 55px;
  z-index: -1;
}
.manage-lifts
  table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root
  .MuiTableCell-footer {
  border-bottom: 0px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-right: 0px;
}
.manage-tickets .tss-1vsygk-MUIDataTableFilterList-root {
  position: absolute;
  margin-left: 0px;
}
.manage-tickets {
  position: relative;
}
.manage-tickets .tss-1cdcmys-MUIDataTable-responsiveBase {
  margin-top: 50px;
}
.manage-tickets table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
  position: absolute;
  top: 55px;
  z-index: -1;
}
.manage-tickets
  table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root
  .MuiTableCell-footer {
  border-bottom: 0px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-right: 0px;
}
.manage-supervisors .tss-1vsygk-MUIDataTableFilterList-root {
  position: absolute;
  margin-left: 0px;
}
.manage-supervisors {
  position: relative;
}
.manage-supervisors .tss-1cdcmys-MUIDataTable-responsiveBase {
  margin-top: 50px;
}
.manage-supervisors table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
  position: absolute;
  top: 55px;
  z-index: -1;
}
.manage-supervisors
  table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root
  .MuiTableCell-footer {
  border-bottom: 0px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-right: 0px;
}
.manage-employees .tss-1vsygk-MUIDataTableFilterList-root {
  position: absolute;
  margin-left: 0px;
}
.manage-employees {
  position: relative;
}
.manage-employees .tss-1cdcmys-MUIDataTable-responsiveBase {
  margin-top: 50px;
}
.manage-employees table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
  position: absolute;
  top: 55px;
  z-index: -1;
}
.manage-employees
  table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root
  .MuiTableCell-root {
  border-bottom: 0px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-right: 0px;
}
.manage-customers .tss-1vsygk-MUIDataTableFilterList-root {
  position: absolute;
  margin-left: 0px;
}
.manage-customers {
  position: relative;
}
.manage-customers .tss-1cdcmys-MUIDataTable-responsiveBase {
  margin-top: 50px;
}
.manage-customers table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
  position: absolute;
  top: 55px;
  z-index: -1;
}
.manage-customers
  table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root
  .MuiTableCell-root {
  border-bottom: 0px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-right: 0px;
}
.manage-subscriptions .tss-1vsygk-MUIDataTableFilterList-root {
  position: absolute;
  margin-left: 0px;
}
.manage-subscriptions {
  position: relative;
}
.manage-subscriptions .tss-1cdcmys-MUIDataTable-responsiveBase {
  margin-top: 50px;
}
.manage-subscriptions table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
  position: absolute;
  top: 55px;
  z-index: -1;
}
.manage-subscriptions
  table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root
  .MuiTableCell-root {
  border-bottom: 0px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-right: 0px;
}
.m-states .tss-1vsygk-MUIDataTableFilterList-root {
  position: absolute;
  margin-left: 0px;
}
.m-states {
  position: relative;
}
.m-states .tss-1cdcmys-MUIDataTable-responsiveBase {
  margin-top: 50px;
}
.m-states table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
  position: absolute;
  top: 55px;
  z-index: -1;
}
.m-states
  table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root
  .MuiTableCell-root {
  border-bottom: 0px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-right: 0px;
}
.m-cities .tss-1vsygk-MUIDataTableFilterList-root {
  position: absolute;
  margin-left: 0px;
}
.m-cities {
  position: relative;
}
.m-cities .tss-1cdcmys-MUIDataTable-responsiveBase {
  margin-top: 50px;
}
.m-cities table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
  position: absolute;
  top: 55px;
  z-index: -1;
}
.m-cities
  table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root
  .MuiTableCell-root {
  border-bottom: 0px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-right: 0px;
}
.m-regions .tss-1vsygk-MUIDataTableFilterList-root {
  position: absolute;
  margin-left: 0px;
}
.m-regions {
  position: relative;
}
.m-regions .tss-1cdcmys-MUIDataTable-responsiveBase {
  margin-top: 50px;
}
.m-regions table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
  position: absolute;
  top: 55px;
  z-index: -1;
}
.m-regions
  table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root
  .MuiTableCell-root {
  border-bottom: 0px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-right: 0px;
}

.profile-tabs form .MuiInputBase-root {
  margin-top: 10px;
}
.profile-tabs .MuiPaper-root.MuiPaper-elevation {
  border-color: #f6f6f6 !important;
  border-radius: 5px;
}
/* .update-address-popup .MuiInputBase-root {
  margin-top: 15px !important;
} */
.new-ticket-form .upload-btn {
  margin-top: 35px;
}
.ticket-media img {
  width: auto;
  max-width: 10%;
}
.upload-img {
  width: auto;
  max-width: 100%;
}
.logo img {
  width: auto;
  height: 100px;
}
.dashboard-logo {
  display: block !important;
}
.dashboard-logo img {
  width: auto;
  max-height: 90px;
}
.profile-pic .MuiAvatar-root {
  width: 32px;
  height: 32px;
}
.profile-pic h6 {
  line-height: 2.1rem;
  margin-left: 5px;
  font-size: 0.867rem;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-pic .MuiIconButton-root {
  padding: 0px;
  margin-left: 15px;
}
.profile-pic .MuiIconButton-root:hover,
.profile-pic .MuiIconButton-root span:hover {
  color: #fe346e;
}
a.MuiTypography-root {
  color: #5c1e93;
}
.signin-form p.MuiTypography-body1 {
  color: #5c1e93;
  font-weight: 600;
  text-transform: uppercase;
}
.signin-admin-form p.MuiTypography-body1 {
  color: #5c1e93;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.signin-admin-form form p.MuiTypography-body1 {
  color: inherit;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 20px;
}
/* .signin-admin-form .Mui-expanded .MuiFormControl-root .MuiInputBase-root,
.signin-admin-form .Mui-focused .MuiFormControl-root .MuiInputBase-root {
  margin-top: 10px;
} */
.view-ticket-title h5 {
  color: #5c1e93;
  font-weight: 600;
  margin-bottom: 1em;
}
.view-ticket-details {
  box-shadow: 1px 1px 4px #5c1e93;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 15px;
}
.view-ticket-details h6 span {
  text-indent: -8px;
}
.view-ticket-comments {
  box-shadow: 1px 1px 4px #5c1e93;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 15px;
}
.view-ticket-comments h6 span {
  font-size: 14px;
}
.dpflex {
  display: flex;
}
.dpblock {
  display: block;
}
.manage-tickets-header .base-FormControl-root {
  display: grid;
  margin-right: 10px;
}
.manage-tickets-header .dpflex {
  max-width: 85%;
}

.manage-tickets-header .dpflex .dpblock h6 {
  position: absolute;
  font-weight: 500;
  z-index: 9;
}
.manage-tickets-header .dpflex .dpblock h6 span {
  color: #5c1e93;
  font-weight: 600;
}
.manage-tickets-header .base-FormControl-root label {
  font-size: 14px;
  line-height: 25px;
  margin-right: 5px;
  float: left;
  padding-left: 0px;
}
.manage-tickets-header .MuiGrid-root .success-btn {
  margin-top: 15px;
  padding: 0.75rem 0.75rem;
}
.manage-tickets-header.m-cat .MuiGrid-root .success-btn {
  margin-top: 0px;
}
.manage-tickets-header .base-FormControl-root .MuiAutocomplete-root {
  float: left;
}
.manage-tickets-header .base-FormControl-root .MuiFormControl-root {
  float: left;
}
.manage-tickets .assign-btn {
  min-width: 100px;
}
.not-found {
  margin-top: 50px;
  text-align: center;
}
.not-found img {
  max-width: 700px;
}
.not-found p {
  margin-top: 50px;
}
.make-active-btn {
  min-width: 125px !important;
}
@media (min-width: 1200px) {
  .not-found {
    margin-top: 50px;
    text-align: center;
    margin-left: 17.125rem;
  }
  .not-found.before-login {
    margin-top: 50px;
    text-align: center;
    margin-left: 0rem;
  }
}
.manage-tickets td .mt-eye-btn {
  float: left;
}
.new-ticket-form .upload-img {
  max-width: 600px;
}
.back-btn {
  position: absolute !important;
  left: 20px;
  top: 20px;
  color: #5c1e93 !important;
}
/* .view-ticket table th{
  padding-left: 0px;
} */
.view-ticket h5 {
  margin-bottom: 10px;
}
header .home-icon .MuiBreadcrumbs-root {
  display: none;
}
header .logout-icons .m-menu {
  background-color: #e1e4ed;
  border-radius: 5px;
}
header .logout-icons button {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}
header .logout-icons .logout-btn {
  font-weight: bold;
}
header .logout-icons .logout-btn:hover {
  color: #fe346e !important;
}
.view-ticket-details img {
  max-width: 100%;
}
.add-address-btn {
  min-width: 115px !important;
}
.priority-btn {
  min-width: 95px;
}
.status-radio-btns .MuiRadio-root {
  border-color: #5c1e93;
}
.MuiCheckbox-root {
  border-color: #5c1e93 !important;
}

.upload-media {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.upload-media .media-container {
  width: 195px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
}
.upload-media .media-container.view-media {
  width: 185px;
}
.upload-media .media-container img {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
}
.upload-media .media-container button {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 5px;
}
/****Home page****/
.why-innovative img {
  width: auto;
  height: 82px;
}
.hiiden {
  display: none;
}
/* ImageSlider.css */
.swiper-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.swiper-pagination-bullet {
  background: #007bff;
}
.swiper-pagination-bullet-active {
  background: #0056b3;
}
footer a span,
footer p,
footer svg {
  color: #ddd !important;
}
.css-xdahnv::after,
.css-xdahnv::before {
  background-color: red !important;
}
.banner {
  min-height: 600px;
  width: 100%;
  /* background-image: url("../assets/images/banner2.jpg"); */
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-background-position: center;
  background-position: center;
  display: grid;
  place-items: center;
  position: relative;
}
.banner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  padding-top: 14rem;
  background-color: rgba(0, 0, 0, 0.1);
}
.banner h1,
.banner p {
  color: #fff;
  text-align: center;
}
/****Home page end****/
/**About Us Page***/
.about-us .MuiBox-root {
  padding-top: 0px;
}
/*****/
/***********Header ************/
.default-header {
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.default-header a.MuiBox-root span.MuiIcon-root {
  color: #5c1e93;
}
.default-header a.MuiBox-root span.MuiTypography-root {
  font-weight: 500;
  text-transform: uppercase;
}
/***********************/
/************Contact Page*************/
.contact-page .MuiSvgIcon-root {
  color: #5c1e93;
}
.contact-form {
  border: 0.0625rem solid #d2d6da;
  border-radius: 0.5rem !important;
}
/************Contact Page End*************/
.quill .ql-editor {
  min-height: 140px;
}
.profile-section {
  position: relative;
}
.profile-section .profile-img {
  border: 1px solid #aaa;
}
.profile-edit {
  position: absolute;
  top: 82px;
  left: 82px;
}
.profile-edit button {
  background-color: #eee;
  padding: 5px;
}
.profile-edit button:hover {
  background-color: #5c1e93;
  padding: 5px;
  color: #fff !important;
}
.multiline-text {
  width: 100% !important;
}

.comments-conv {
  box-shadow: 1px 1px 4px #5c1e93;
  border-radius: 5px;
  padding: 20px 0px 0px 20px;
  margin-bottom: 15px;
}
.comments-conv h5 {
  height: 450px;
  overflow-y: auto;
  position: relative;
}
.comments-conv h5 .MuiBox-root.chat-box {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column-reverse;
}
.comments-conv h5 .comments-chat {
  margin-bottom: 5px;
}
.comments-conv.mr0ml10 {
  margin-left: 10px;
  margin-right: 0px;
}
.add-comment {
  position: sticky;
  left: 0px;
  bottom: 0px;
  width: 98%;
  background-color: #fff;
  padding: 10px 0px;
  top: 400px;
}
.add-comment button {
  margin-left: 20px;
}
.search-Box {
  position: absolute;
  top: 10px;
  left: 35%;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  gap: 8px;
  background-color: "white";
}
@media (min-width: 900px) and (max-width: 1240px) {
  .manage-lifts table thead th:last-child {
    width: 25%;
  }
}

@media (max-width: 1199px) {
  .logout-icons {
    align-items: center;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    justify-content: space-between !important;
    width: 100% !important;
  }
}
@media (max-width: 899.95px) {
  .manage-tickets td.MuiTableCell-body div:first-child {
    width: calc(37%);
  }
  .manage-tickets td.MuiTableCell-body div:last-child {
    font-size: 14px;
  }
  .manage-subscriptions td.MuiTableCell-body div:first-child {
    width: calc(37%);
  }
  .manage-subscriptions td.MuiTableCell-body div:last-child {
    font-size: 14px;
  }
  .manage-customers td.MuiTableCell-body div:first-child {
    width: calc(37%);
  }
  .manage-customers td.MuiTableCell-body div:last-child {
    font-size: 14px;
  }
  table td.datatables-noprint {
    text-align: center !important;
  }
  table td.datatables-noprint .datatables-noprint {
    width: 100% !important;
    text-align: center !important;
  }
  table td.datatables-noprint .datatables-noprint .MuiTypography-body1 {
    width: 100% !important;
    text-align: center !important;
  }
  .not-found img {
    max-width: 80%;
  }
  .signin-admin-form {
    max-width: 40vw !important;
  }
  .signin-form {
    max-width: 40vw !important;
  }
  .manage-tickets-header {
    display: block !important;
  }
  .manage-tickets-header .MuiGrid-root .MuiButton-root {
    margin-top: 25px;
  }
  header .MuiToolbar-root {
    min-height: 40px;
  }
  header .home-icon {
    display: none;
    height: 0px;
  }
  /* header .logout-icons{
    justify-content: flex-start;
  } */
}
@media (max-width: 767.95px) {
  .home-icon {
    display: none !important;
  }
  .search-Box {
    top: 55px;
    left: 1%;
  }
  .logout-icons .m-menu {
    line-height: 1;
  }
  .new-ticket-form .upload-btn {
    margin-top: 0px;
  }
  .profile-tabs form .MuiGrid-root label.MuiFormControlLabel-root {
    margin-top: 0px;
  }
  .banner {
    min-height: 600px;
    -webkit-background-position: left;
    background-position: left;
  }
  .banner-overlay {
    padding-top: 10rem;
  }
  .banner h1 {
    font-size: 2rem;
  }
  .why-innovative {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .about-us {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .about-us .MuiBox-root {
    padding-top: 32px;
  }
  .about-us .MuiBox-root {
    padding-left: 0px;
  }
  .contact-page {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
}
@media (max-width: 991.95px) {
  .manage-lifts .tss-1cdcmys-MUIDataTable-responsiveBase {
    margin-top: 50px;
  }
  .manage-lifts table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
    top: 70px;
  }
  .manage-lifts .tss-1vsygk-MUIDataTableFilterList-root {
    position: inherit;
    margin-left: 0px;
    top: 45px;
  }
  .manage-tickets .tss-1cdcmys-MUIDataTable-responsiveBase {
    margin-top: 50px;
  }
  .manage-tickets .tss-1vsygk-MUIDataTableFilterList-root {
    position: inherit;
    margin-left: 0px;
    top: 45px;
  }
  .manage-tickets table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
    top: 70px;
  }
  .manage-supervisors .tss-1vsygk-MUIDataTableFilterList-root {
    position: inherit;
    margin-left: 0px;
    top: 45px;
  }
  .manage-supervisors .tss-1cdcmys-MUIDataTable-responsiveBase {
    margin-top: 50px;
  }
  .manage-supervisors table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
    top: 70px;
  }
  .manage-employees .tss-1vsygk-MUIDataTableFilterList-root {
    position: inherit;
    margin-left: 0px;
    top: 45px;
  }
  .manage-employees .tss-1cdcmys-MUIDataTable-responsiveBase {
    margin-top: 50px;
  }
  .manage-employees table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
    top: 70px;
  }
  .manage-customers .tss-1vsygk-MUIDataTableFilterList-root {
    position: inherit;
    margin-left: 0px;
    top: 45px;
  }
  .manage-customers .tss-1cdcmys-MUIDataTable-responsiveBase {
    margin-top: 50px;
  }
  .manage-customers table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
    top: 70px;
  }
  .manage-subscriptions .tss-1vsygk-MUIDataTableFilterList-root {
    position: inherit;
    margin-left: 0px;
    top: 45px;
  }
  .manage-subscriptions .tss-1cdcmys-MUIDataTable-responsiveBase {
    margin-top: 50px;
  }
  .manage-subscriptions table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
    top: 70px;
  }
  .m-states .tss-1vsygk-MUIDataTableFilterList-root {
    position: inherit;
    margin-left: 0px;
    top: 45px;
  }
  .m-states .tss-1cdcmys-MUIDataTable-responsiveBase {
    margin-top: 50px;
  }
  .m-states table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
    top: 70px;
  }
  .m-cities .tss-1vsygk-MUIDataTableFilterList-root {
    position: inherit;
    margin-left: 0px;
    top: 45px;
  }
  .m-cities .tss-1cdcmys-MUIDataTable-responsiveBase {
    margin-top: 50px;
  }
  .m-cities table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
    top: 70px;
  }
  .m-regions .tss-1vsygk-MUIDataTableFilterList-root {
    position: inherit;
    margin-left: 0px;
    top: 45px;
  }
  .m-regions .tss-1cdcmys-MUIDataTable-responsiveBase {
    margin-top: 50px;
  }
  .m-regions table.MuiTable-root.tss-5jmwmz-MUIDataTableFooter-root {
    top: 70px;
  }
}
@media (max-width: 575px) {
  .css-kn6rnd {
    min-width: 340px !important;
  }
  .css-1n052xk {
    min-width: 340px !important;
  }
  .css-mgnyu8 {
    min-width: 340px !important;
  }
  .profile-tabs .MuiTabs-flexContainer {
    display: block;
  }
  .profile-tabs .MuiTabs-flexContainer .MuiButtonBase-root {
    width: 100%;
    min-height: 50px !important;
  }
  .profile-tabs .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected {
    background-color: #5c1e93;
    color: #fff !important;
  }
  form .MuiGrid-root label.MuiFormControlLabel-root {
    margin-top: 0px;
  }
  .ticket-media img {
    width: auto;
    max-width: 100%;
  }
  .upload-img {
    width: auto;
    max-width: 100%;
  }
  .manage-tickets-header .base-FormControl-root label {
    margin-right: 5px;
    margin-top: 10px;
  }
  .manage-tickets-header .base-FormControl-root .MuiAutocomplete-root {
    width: 100%;
  }
  .dpflex {
    display: block;
  }
  .manage-tickets-header .dpflex .dpblock {
    display: grid;
  }
  .manage-tickets-header .dpflex .dpblock h6 {
    position: inherit !important;
  }
  .manage-tickets-header .MuiGrid-root .success-btn {
    margin-top: 10px;
  }
  .manage-tickets-header
    .base-FormControl-root
    .MuiAutocomplete-root
    .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 0.6rem 1.75rem 0.6rem 0.65rem !important;
  }
  .manage-tickets-header .base-FormControl-root {
    display: block;
  }
  .view-ticket-details img {
    width: 100%;
  }
  .edit-ticket-form .MuiGrid-root img {
    width: 100%;
  }
  table td .MuiIconButton-root {
    padding: 0px;
  }
  .new-ticket-form .upload-img {
    width: 100%;
  }
  /** 5/7/24***/
  .logo img {
    width: auto;
    height: 50px;
  }
  /** 5/7/24 End***/
  .MuiCard-root .MuiPaper-root.MuiPaper-elevation .MuiToolbar-root {
    overflow-x: auto;
  }
  .signin-form {
    max-width: 80vw !important;
  }
}
.hidden {
  display: none !important;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #837392;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5c1e93;
}
.static-cards .MuiPaper-root .MuiBox-root .MuiTypography-root {
  color: #67748e !important;
}
.static-cards
  .MuiPaper-root
  .MuiBox-root
  h5
  .MuiTypography-root.MuiTypography-button {
  color: rgb(203, 12, 159) !important;
}
